<template>
  <div class="covid">
    COVID-19 Last day Cases [ITALY]:
    <h1>{{ dayStat }}</h1>

    Confirmed cases:

    {{ myLast.Cases }}
    <hr />
    {{ myLast.Date }}
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Covid",
  data() {
    return {
      patr: 1050,
      amount: 1
    };
  },
  methods: {
    ...mapActions(["getStatus"])
  },
  computed: mapGetters(["myStatus", "myLast", "dayStat"]),
  created() {
    this.getStatus();
  }
};
</script>

<style scoped>
.amount-input {
  height: 36px;
  width: 200px;
  font-size: 24px;
  text-align: center;
  border: 4px solid cornsilk;
  border-radius: 25px;
  outline-style: none;
  background-color: #f15946;
}
.res {
  font-size: 42px;
  text-align: center;
  padding-top: 42px;
}
</style>
